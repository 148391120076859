import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import pluralize from 'pluralize'
import styled from 'styled-components'
import ProductGrid3Col from '../product_grid_3_col'

let numresult = 0;

const Title = styled.h3`
  text-align: center;
  text-transform: none;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 100px;
  display: block;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
`

const isMatch = (searchOnString, searchText) => {
  var searchText = searchText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

  var plural = pluralize(searchText);
  var singular = pluralize.singular(searchText);

  if(searchOnString.includes(searchText)) {
    return true;
  } else if(searchOnString.includes(plural)) {
    return true;
  } else if(searchOnString.includes(singular)) {
    return true;
  } else {
    return false;
  }
  // return searchOnString.match(new RegExp("\\b"+searchText+"\\b", "i")) != null;
}

const SearchResultsWrapper = styled.div`
  padding-top: 94px;
  width: 100%;
`

class SearchResults extends Component {

  render() {
    const { search } = this.props;
    const lowerSearch = search.toLowerCase().trim();
    return (
        <StaticQuery
          query={graphql`
          query SearchQueryAll {
            products: allShopifyProduct(
              filter: {tags: {ne: "Hidden on /shop"}}, sort: { fields: [title], order: ASC }
            ) {
              edges {
                node {
                  id
                  handle
                  availableForSale
                  title
                  description
                  productType
                  variants {
                    shopifyId
                    title
                    price
                    availableForSale
                  }
                  images {
                    id
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 910) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `}
          render={({ products }) => (
            <>
              <Title>{(numresult = products.edges
                .filter(product => (
                  isMatch(product.node.title.toLowerCase(), lowerSearch) ||
                  isMatch(product.node.description.toLowerCase(), lowerSearch)
                  )
                ).length)
              } results for ‘{search}’</Title>
              {products.edges
                .filter(product => (
                  isMatch(product.node.title.toLowerCase(), lowerSearch) ||
                  isMatch(product.node.description.toLowerCase(), lowerSearch)
                  )
                )
                .map((product, index) => (
                  <ProductGrid3Col key={product.node.id} product={product.node} />
                ))
              }
            </>
          )}
        />
    );
  }
}

export default SearchResults;
