import React, { Component } from 'react';
import SearchResults from '../../components/search_results'
import { Container, Row } from 'styled-bootstrap-grid2'
import Container3Col from '../../components/container_3col'

class SearchPage extends Component {
  render() {
    let searchString = null;
    if(this.props.location.state !== undefined && this.props.location.state.search !== undefined) {
      searchString = this.props.location.state.search;
      return (
        <div>
          <Container3Col longpage top>
            <Container>
              <Row>
                <SearchResults search={searchString} />
              </Row>
            </Container>
          </Container3Col>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SearchPage;
