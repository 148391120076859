import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: ${props => props.top ? "flex-start" : "center"};
  justify-content: center;
  max-width: calc(100vw - 230px);
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => props.longpage ? "140px" : "0"};
  padding-bottom: ${props => props.longpage ? "2rem" : "0"};
  .react-reveal {
    height: calc(100% - 130px);
  }

  @media (max-width: 768px) {
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    max-width: none;
    align-items: flex-start;
    div {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  p {
    padding-bottom: 2rem;
    color: ${props => props.theme.brown4};
    padding-right: 4rem;
    @media (max-width: 768px) {
      padding-right: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
    }
    a, i {
      text-decoration: none;
      font-style: italic;
      color: ${props => props.theme.brown4};
      transition: all 0.25s ease-in-out;
    }
    a:hover {
      color: ${props => props.theme.brown4};
    }
  }
`

class Container3Col extends Component {
  render() {
    return (
      <Container {...this.props}>
        {this.props.children}
      </Container>
    );
  }
}

export default Container3Col;
