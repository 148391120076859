/* 3 column grid of image thumbnails */

import React, { Component } from 'react';
import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid2'
import Fade from 'react-reveal/Fade';
import ProductThumbnailPreview from '../product_thumbnail_preview'

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
}

class ProductGrid3Col extends Component {

  render() {

    const { product, hidden, gemFilter } =  this.props;
    let intersection = false;

    // hidden is a filter to remove products with a certain tag (shopify category)
    if(hidden !== undefined && hidden.length > 0) {
      intersection = hidden.filter(x => product.tags.includes(x));
    }

    // hiding products where they include tag x
    if(intersection.length > 0) {
      return (
        <div></div>
      )
    } else {

      if(gemFilter !== undefined && gemFilter !== false && product.tags.includes(gemFilter)) {
        return (
          <Col xl="4" lg="4" md="4" sm="6" xs="6">
            <Fade bottom delay={getRandomNumber(250,1000)} style={{ height: '100%'}}>
              <ProductThumbnailPreview key={product.id} product={product} />
            </Fade>
          </Col>
        );
      } else if(gemFilter !== undefined && gemFilter !== false && !product.tags.includes(gemFilter)) {
        return (
          <div></div>
        )
      } else {
        return (
          <Col xl="4" lg="4" md="4" sm="6" xs="6">
            <Fade bottom style={{ height: '100%'}} delay={getRandomNumber(250,1000)}>
              <ProductThumbnailPreview key={product.id} product={product} />
            </Fade>
          </Col>
        );
      }
    }
  }
}

export default ProductGrid3Col;
